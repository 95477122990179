@keyframes into-the-abyss {
  from {
    top: 25vh;
    transform: translateX(-50%);
    opacity: 1;
    animation-timing-function: ease-in;
  }

  20% {
    opacity: 0.1;
    top: 75vh;
    transform: translateX(-50%) scale(0.2);
    animation-timing-function: ease-out;
  }

  to {
    top: 100vh;
    transform: translateX(-50%) scale(0);
    opacity: 0;
    text-shadow: none;
  }
}

.message {
  top: 25vh;
  transform: translate(-50%, 0);
  text-align: center;
  line-height: 0.7rem;
  position: absolute;
  left: 50%;
  animation-duration: 6s;
  z-index: 1;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  color: white;
  text-shadow: 0 0.5rem 0 black;
  cursor: default;
  font-size: 5rem;
}

input.messageInput {
  transform: translateY(-3.5rem);
  text-align: inherit;
  font-family: inherit;
  border: 0;
  outline: 0;
  width: 50vw;
  max-width: 50vw;
  font-size: inherit;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  border: 0.5rem dashed white;
  color: inherit;
}

input.messageInput:focus {
  border-color: #fca311;
}

input.messageInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 600px) {
  .message {
    font-size: 2rem;
  }

  input.messageInput {
    width: inherit;
    border: 1px dashed white;
  }
}
