.abyss {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  background: #233a6c;
}

.abyss > div {
  position: absolute;
  bottom: -50vw;
  width: 100vw;
  height: 100vw;
  left: 0;
  transform: scaleX(1.5) scaleY(0.3);
  border-radius: 50%;
  background: radial-gradient(circle, #000000 10%, #14213d 100%);
}

@media (orientation: portrait) {
  .abyss > div {
    transform: scaleX(5) scaleY(1.5);
  }
}
